import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '../../../../components/Box/Box'
import { BoxStatistic } from '../../../../components/BoxStadistic/ BoxStatistic'
import { CardGird } from '../../../../components/CardGrid/CardGird'
import { NavButtons } from '../../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../../components/TableInfo/TableInfo'
import useEM from '../../../../hooks/useEM'
import useView from '../../../../hooks/useView'
import { HistoryToLinkedeIdWrapper } from './HistoryToLinkedeId.style'
import { NotFoundScreen } from '../../../NotFoundScreen/NotFoundScreen';
import { dataScreenHistory } from '../../../../data/dataEM';

import dateFormat from 'dateformat';

export const HistoryToLinkedeId = () => {
    const { arrayEMAlerts, getEMAlerts,histories } = useEM()
    const [search, setSearch] = useState([])
    const { changeView, isCardView } = useView()

    const location = useLocation()
    const navigate = useNavigate()

    const { notFound } = dataScreenHistory;

    useEffect(() => {
        setSearch([])
        getEMAlerts()
    }, [setSearch])

    const COLUMNS = [
        {
            Header: 'ID llamada',
            accessor: 'uniqueid',
        },
        {
            Header: 'Alerta',
            accessor: 'cidDnid',
        },
        {
            Header: 'Extensión receptora',
            accessor: 'receiverExtension',
        },
        {
            Header: 'Duración',
            accessor: 'calculateTime',
        },
        {
            Header: 'Hora recepción',
            accessor: 'hourReceived',
            Cell: ({ row }) => (
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.original.hourReceived,
                    }}
                />
            ),
        },
        {
      Header: 'Estado',
      accessor: 'context',
      Cell: ({ row }) =>
        (row.original.context.find((call) => call.eventtype === 'EM_STATUS_END'))  ? (
          <Box name={'contestada'} backgroundColor={'#DFF5F3'} />
        ) : (
         <Box name={'no contestada'} backgroundColor={'#FBEDD2'} />
        ),
    },
  ];

  const timeForGraph = (totalCallsTime) => {
        const totalTime = totalCallsTime.reduce((total, call) => {
            if (call.time !== 'No escuchada') {
                const minutes = Number(call.time.slice(0, 2)) * 60
                const seconds = Number(call.time.slice(3, 5))

                return total + minutes + seconds
            } else return total + 0
        }, 0)

        return totalTime / totalCallsTime.length
    }

    function calculateTime(item) {
        let answer = item.eventtime
        let hangup = ''
        let diffTime = ''
        item.callFlow.forEach((item2) => {
            if (item2.userdeftype === 'EM_STATUS_END' || item2.userdeftype === 'EM_STATUS_FAIL') {
                hangup = item2.eventtime
            }
        })

        if (answer !== '' && hangup !== '') {
            diffTime = moment.utc(moment(hangup, 'YYYY/MM/DD HH:mm:ss').diff(moment(answer, 'YYYY/MM/DD HH:mm:ss'))).format('ss')
        }
        if (diffTime !== '') {
            return diffTime + ' segundos'
        } else {
            return 'No escuchada'
        }
    }

    let receiverExtensions = location.state.itemsSameLinkedId.filter((obj) => obj.eventtype !== 'EM_STATUS_START')

    function foundData(data, search) {
        let substrings = data.split(',')
        let src = substrings.find((substring) => substring.includes(search))
        if (src) {
            let srcValue = src.substring(src.indexOf(':') + 1)
            return srcValue
        }
    }

  function foundTime(date) {
    const shortDate = date.substr(0, date.length - 5);
    const newDate = dateFormat(shortDate, 'yyyy-m-dd HH:MM:ss');
    return newDate;
  }

    function foundNameAlert(name) {
        let newName
        arrayEMAlerts.forEach((item) => {
            if (item.keyCall === name) {
                newName = item.name
            }
        })
        return newName
    }

    let originateStatus = receiverExtensions.map(
        ({
            accountcode,
            amaflags,
            appdata,
            appname,
            channame,
            cidAni,
            cidDnid,
            cidName,
            cidNum,
            cidRdnis,
            context,
            agent,
            eventtime,
            eventtype,
            exten,
            extra,
            id,
            linkedid,
            peer,
            peeraccount,
            uniqueid,
            userdeftype,
            userfield,
        }) => ({
            accountcode,
            amaflags,
            appdata,
            appname,
            channame,
            cidDnid: foundData(appdata, 'emcode:'),
            cidName,
            cidNum,
            cidRdnis,
            context: foundData(appdata, 'ORIGINATE_STATUS:'),
            agent: foundData(appdata, 'agent:'),
            eventtime:foundTime(eventtime),
            eventtype,
            exten,
            extra,
            id,
            linkedid,
            peer,
            peeraccount,
            uniqueid,
            userdeftype,
            userfield,
            receiverExtension: foundData(appdata, 'dst:'),
            hourReceived: foundTime(eventtime),
            callFlow: [],
        })
    )

    const groupCalls = []
    originateStatus.forEach((item) => {
        let index = groupCalls.map((ext) => ext.receiverExtension).indexOf(item.receiverExtension)
        if (index !== -1) {
            groupCalls[index].callFlow.push(item)
        } else {
            groupCalls.push(item)
        }
    })

    function addState(item) {
        item.callFlow.forEach((item2) => {
            if (item2.context === 'ANSWER') {
                item.action = item2.context
            }
            // if(item2.context === 'otra cosa'){
            //     item.action = item2.context
            // }
        })
    }

    groupCalls.forEach((item) => {
        if (item.callFlow.length > 0) {
            item.calculateTime = calculateTime(item)
            addState(item)
        }
        else {
            item.calculateTime = 'No escuchada'
        }
        // if (item.cidDnid) {
        //     item.cidDnid = foundNameAlert(item.cidDnid
        // }
    })

    const DATA = groupCalls.map((item) => ({
        uniqueid: item.uniqueid,
        receiverExtension: item.agent ? item.agent : item.receiverExtension,
        calculateTime: item.calculateTime,
        context: item.callFlow.length > 0 ? item.callFlow : 'No',
        hourReceived: item.hourReceived,
        cidDnid: item.cidDnid,
        id: item.id,
    }))

    const dataToCards = []

    const dataPie = [
        { name: 'Lunes', num: 100, color: '#7357F6' },
        { name: 'Martes', num: 313, color: '#ED7E63' },
        { name: 'Miércoles', num: 300, color: '#97DFD8' },
        { name: 'Jueves', num: 400, color: '#F0BD58' },
        { name: 'Viernes', num: 231, color: '#84BBF0' },
    ]

const answerCount = groupCalls.filter((object) => object.callFlow.find((call) => call.eventtype === 'EM_STATUS_END')).length;
// const failedCount = groupCalls.filter((object) => object.eventtype === 'EM_STATUS_FAIL').length;
const totalCount = DATA?.length;

const dataStatistics = [
    {
      title: 'Total',
      value: totalCount,
      backgroundColor: '#F4F2FF',
    },
    // {
    //   title: 'Errores',
    //   value: failedCount ? failedCount : 0,
    //   backgroundColor: '#FBD2D2',
    // },
    {
      title: 'Contestadas',
      value: answerCount,
      backgroundColor: '#DFF5F3',
    },
    {
      title: 'No contestadas',
      value: totalCount - answerCount,
      backgroundColor: '#FBEDD2',
    },
  ];

  const handleReturn = () => {
        navigate('/EM/history', {})
    }

   const handleOpenFormEdit = () => {
    return false;
  };

    return (
        <HistoryToLinkedeIdWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        changeView: {
                            position: 'right',
                            function: changeView,
                            isCardView: isCardView,
                        },
                        file: {
                            position: 'right',
                            data: DATA,
                            import: false,
                        },
                        return: {
                            position: 'left',
                            function: handleReturn,
                        },
                    }}
                    setSearch={setSearch}
                />
            </div>
            {histories[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} />
      ):search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : isCardView ? (
                <div className="contain__cardGrid">
                    <CardGird data={dataToCards} onClick={() => null} />
                </div>
            ) : (
                <div className="contain__table-statistics">
                    <div className="contain__statistics">
                        <TableInfo onClick={handleOpenFormEdit} format={'list'} COLUMNS={COLUMNS} DATA={DATA} deleteFunction={null} updateFunction={null} isOptions={false} />
                    </div>
                    <div className="boxStatistic">
                        {dataStatistics.map((item, index) => (
                            <div className="box" key={index}>
                                <BoxStatistic backgroundColor={item.backgroundColor} title={item.title} value={item.value} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </HistoryToLinkedeIdWrapper>
    )
}
